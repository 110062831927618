<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-toolbar flat color="transparent" height="40">
          <v-toolbar-title>
            <h3 class="headline mb-0 font-weight-bold main--text">
              {{ getCompany.display_name }}
            </h3>
          </v-toolbar-title>

          <v-divider class="mx-4" vertical></v-divider>

          <v-row align="center">
            <v-col cols="auto">
              <h5 class="subtitle-2 mb-0">
                {{ getCompany.contact_name }} {{ $t('honorific') }}
              </h5>
            </v-col>
            <v-col v-for="item in mainMenu" :key="item.text" cols="auto">
              <v-btn
                small
                text
                exact
                :to="{ name: item.to }"
                exact-active-class="primary--text"
                :ripple="false"
                class="text-capitalize font-weight-regular btn-menu"
              >
                <v-icon left>{{ item.icon }}</v-icon>
                {{ item.text }}
              </v-btn>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>

          <v-row class="flex-row-reverse">
            <v-col cols="auto">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="text-capitalize font-weight-regular"
                    text
                  >
                    <v-icon color="secondary">mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in dropdownMenu"
                    :key="index"
                  >
                    <v-list-item-title>
                      <v-btn
                        small
                        text
                        exact
                        :to="{ name: item.to }"
                        exact-active-class="primary--text"
                        :ripple="false"
                        class="text-capitalize font-weight-regular btn-menu"
                      >
                        <v-icon left>{{ item.icon }}</v-icon>
                        {{ item.text }}
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="auto">
              <v-btn
                small
                class="text-capitalize font-weight-regular"
                text
                @click="
                  $router.push({
                    name: 'Companies',
                    query: { status: 'Connect', type: 'corporate' }
                  })
                "
              >
                <v-icon color="secondary" left>$goBack</v-icon>
                {{ $t('go_back') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </v-col>
    </v-row>

    <router-view v-if="!loading"></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'index',
  created() {
    this.loading = true
    this.$store.dispatch('COMPANY_GET', { id: this.$route.params.id })
    this.$store
      .dispatch('COMPANY_BUSINESS_YEARS_GET', {
        id: this.$route.params.id
      })
      .finally(() => {
        this.loading = false
      })
  },
  data() {
    return {
      loading: false,
      menus: [
        {
          icon: '$info',
          text: this.$t('page_company_detail_menu_info'),
          to: 'CompanyDetailInfo',
          onlyConnected: false,
          showAt: 'dropdown'
        },
        {
          icon: '$accounting',
          text: this.$t('page_company_detail_menu_accounting'),
          to: 'CompanyDetailAccounting',
          onlyConnected: true,
          showAt: 'dropdown'
        },
        {
          icon: '$performance',
          text: this.$t('page_company_detail_menu_performance'),
          to: 'CompanyDetailPerformance',
          onlyConnected: true,
          showAt: 'main'
        },
        {
          icon: '$sales',
          text: this.$t('page_company_detail_menu_sales_cost'),
          to: 'CompanyDetailSalesCost',
          onlyConnected: true,
          showAt: 'main'
        },
        {
          icon: '$gear',
          text: this.$t('page_company_detail_menu_fl_goal'),
          to: 'CompanyDetailFLGoal',
          onlyConnected: true,
          showAt: 'dropdown'
        },
        {
          icon: '$gear',
          text: this.$t('page_company_detail_menu_sales_goal'),
          to: 'CompanyDetailSalesGoal',
          onlyConnected: true,
          showAt: 'dropdown'
        },
        {
          icon: '$bank',
          text: this.$t('page_company_detail_menu_bank_balance'),
          to: 'CompanyDetailBankBalance',
          onlyConnected: true,
          showAt: 'main'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['getCompany']),
    filteredMenu() {
      if (this.$store.getters.getCompany.status?.name !== 'Connect')
        return this.menus.filter(item => item.onlyConnected === false)
      return this.menus
    },
    dropdownMenu() {
      return this.filteredMenu.filter(item => item.showAt === 'dropdown')
    },
    mainMenu() {
      return this.filteredMenu.filter(item => item.showAt === 'main')
    }
  },
  methods: {}
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
